import {
  Breadcrumbs,
  generateBreadcrumb,
} from "../../../components/Breadcrumbs";
import { MetaTags } from "../../../components/MetaTags";
import { ProfileBadge } from "../../../components/profile/ProfileBadge";
import { SharedItem } from "../../../types/SharedItem";
import { LessonCard } from "../../../components/lessons/LessonCard";
import { useEffect } from "react";

export interface SharedLessonViewerProperties {
  accessKey?: string;
  goalId?: string;
  sharedItem: SharedItem;
}

export function SharedLessonViewer(
  properties: SharedLessonViewerProperties,
): JSX.Element {
  const { ownerId, ownerEmail, ownerName, id, sharedLesson } =
    properties.sharedItem;

  useEffect(() => {
    console.log("lesson properties::", properties);
  }, []);

  const lesson = sharedLesson?.lesson;
  if (lesson == undefined) {
    throw new Error("lesson must be provided");
  }

  const titleTag = `Shared Lesson: ${lesson.title}`;
  const descriptionTag = `Shared Lesson from ${ownerName}`;

  const breadcrumbs = generateBreadcrumb(
    generateBreadcrumb([], "Sharing & Privacy", "/sharing"),
    `Lesson: "${lesson?.title}" from ${ownerName}` || "",
    `/shared/lesson/${ownerId}/${id}`,
    `${ownerId}/${id}`,
  );

  return (
    <main>
      <MetaTags title={titleTag} description={descriptionTag} />

      {breadcrumbs.length > 0 && (
        <Breadcrumbs
          basePath="/dashboard"
          breadcrumbs={breadcrumbs}
        ></Breadcrumbs>
      )}

      <div className="max-w-6xl w-full mx-auto xs:pt-8 sm:pt-12 xs:pb-4 sm:pb-8 xs:px-4 sm:px-8">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <ProfileBadge id={ownerId} name={ownerName} email={ownerEmail} />
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 flex"></div>
        </div>

        <hr className="mt-6 mb-6" />

        <LessonCard lesson={lesson}></LessonCard>
        <p>{lesson.description}</p>
      </div>
    </main>
  );
}
