import { MetaTags } from "../../components/MetaTags";
import imgLogo from "../../images/branding/syllabyte-horizontal-logo.svg";

export function Soon(): JSX.Element {
  return (
    <main>
      <div className="max-w-7xl w-full mx-auto xs:pt-4 sm:pt-6 xs:pb-4 sm:pb-8 xs:px-4 sm:px-8">
        <MetaTags title="Soon..." description="Coming Soon" />

        <div className="text-center max-w-7xl mx-auto sm:px-6 lg:px-8">
          <section id="soon" className={`overflow-hidden`}>
            <div className="max-w-7xl mx-auto w-full">
              <div className="py-12 xs:px-4 sm:px-8">
                <img
                  loading="lazy"
                  src={imgLogo}
                  alt={"Syllabyte.ai"}
                  title={"Syllabyte.ai"}
                  className="w-96 block mx-auto"
                />
                <h1 className="my-6 text-3xl">
                  Revolutionising Publishing with AI-Driven Content Creation,
                  Alignment, Accessibility, and Security
                </h1>
                <div className="mt-12 prose max-w-full">
                  <p>soon...</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}

export default Soon;
