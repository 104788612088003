import { Fragment, useState, useEffect } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { CheckIcon, ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ListItem } from "../../types/ListItem";

interface DropdownProperties {
  name: string;
  label?: string;
  options: ListItem[];
  selectedKey: string;
  disabled?: boolean;
  onChange?: (key: string) => void;
}

export const Dropdown = (properties: DropdownProperties): JSX.Element => {
  const { name, label, options, selectedKey, disabled, onChange } = properties;
  const [selectedValue, setSelectedValue] = useState<string>(selectedKey);

  useEffect(() => {
    setSelectedValue(selectedKey);
  }, [selectedKey]);

  const handleOnChange = (itemKey: string) => {
    setSelectedValue(itemKey);

    if (onChange) {
      onChange(itemKey);
    }
  };

  function getSelectedOption() {
    return options.find(option => option.key === selectedValue);
  }

  return (
    <>
      <Listbox
        disabled={disabled}
        value={selectedValue}
        name={name}
        onChange={handleOnChange}
      >
        {({ open }) => (
          <>
            {label && <Label className="font-medium pb-2">{label}</Label>}
            <div className="relative">
              <ListboxButton className="bg-white relative w-full border disabled:bg-gray-100 border-gray-300 rounded-md shadow-sm pl-3 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-syllabyteTurquoise focus:border-syllabyteTurquoise sm:text-sm">
                <span className="flex items-center">
                  {getSelectedOption() !== undefined &&
                    getSelectedOption()?.iconPath && (
                      <img
                        src={getSelectedOption()?.iconPath}
                        alt={getSelectedOption()?.label}
                        className="h-4 w-4 mr-2 flex"
                      />
                    )}
                  <span className="block truncate">
                    {options.find(option => option.key === selectedValue)
                      ?.label || "Please Select"}
                  </span>
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ArrowsUpDownIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions
                  static
                  className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {options.map(option => (
                    <ListboxOption
                      key={option.key}
                      value={option.key}
                      className={({ active }) =>
                        clsx(
                          active ? "text-white bg-syllabyteTurquoise" : "",
                          "cursor-default select-none relative py-2 pl-3 pr-9",
                        )
                      }
                    >
                      {({ selected, active }) => (
                        <span className="flex items-center">
                          {option.iconPath && option.iconPath.length > 0 && (
                            <img
                              src={option.iconPath}
                              alt={option.label}
                              className="h-4 w-4 mr-2 flex"
                            />
                          )}
                          <span
                            className={clsx(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                            )}
                          >
                            {option.label}
                          </span>
                          {selected ? (
                            <span
                              className={clsx(
                                active
                                  ? "text-white"
                                  : "text-syllabyteTurquoise",
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : undefined}
                        </span>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};
