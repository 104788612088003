import { Field, FieldProps } from "formik";
import clsx from "clsx";
import { useState, useRef, useEffect } from "react";

interface TextInputFieldProperties {
  name: string;
  label?: string;
  rows?: number;
  helper?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    form: Pick<FieldProps["form"], "setFieldValue">,
  ) => void;
  onFinishedInput?: (value: string) => void;
}

export const TextInputField = ({
  name,
  label,
  rows,
  helper,
  placeholder,
  required,
  disabled,
  error,
  onChange,
  onFinishedInput,
}: TextInputFieldProperties): JSX.Element => {
  const className =
    "text-syllabyteBlack shadow focus:ring-syllabyteTurquoise focus:border-syllabyteTurquoise block w-full disabled:bg-gray-100 border-gray-300 rounded-md";

  const labelClassName = "block font-medium mb-1";

  const inputClassName = clsx(className, {
    "resize-y min-h-fit": rows && rows > 1,
  });

  const labelId = `${name}-label`;
  const inputId = `${name}-input`;

  const [inputValue, setInputValue] = useState("");
  const debouncedValueReference = useRef<ReturnType<typeof setTimeout>>();
  const [lastDebouncedValue, setLastDebouncedValue] = useState("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    form: Pick<FieldProps["form"], "setFieldValue">,
  ) => {
    const { name: eventName, value: eventValue } = event.target;
    form.setFieldValue(eventName, eventValue);
    setInputValue(eventValue);

    if (onChange) {
      onChange(event, form);
    }
  };

  const onBlur = () => {
    clearTimeout(debouncedValueReference.current);
    if (
      onFinishedInput &&
      inputValue.trim() !== "" &&
      inputValue !== lastDebouncedValue
    ) {
      onFinishedInput(inputValue);
      setLastDebouncedValue(inputValue);
    }
  };

  useEffect(() => {
    if (debouncedValueReference.current) {
      clearTimeout(debouncedValueReference.current);
    }

    debouncedValueReference.current = setTimeout(() => {
      if (
        onFinishedInput &&
        inputValue.trim() !== "" &&
        inputValue !== lastDebouncedValue
      ) {
        setLastDebouncedValue(inputValue);
        onFinishedInput(inputValue);
      }
    }, 1000); // 1sec debounce

    return () => clearTimeout(debouncedValueReference.current);
  }, [inputValue, onFinishedInput, lastDebouncedValue]);

  return (
    <div>
      {label && (
        <label htmlFor={inputId} id={labelId} className={labelClassName}>
          {label}
        </label>
      )}

      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <>
            {(!rows || rows < 2) && (
              <input
                type="text"
                {...field}
                id={inputId}
                placeholder={placeholder}
                required={required || false}
                disabled={disabled}
                spellCheck={true}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
                onBlur={onBlur}
                aria-required={required || false}
                aria-label={label}
                aria-describedby={`${helper ? `${name}-helper` : ""} ${
                  form.errors[name] ? `${name}-error` : ""
                }`}
                onChange={event => handleChange(event, form)}
                className={inputClassName}
              />
            )}

            {rows && rows > 1 && (
              <textarea
                {...field}
                id={inputId}
                rows={rows}
                placeholder={placeholder}
                spellCheck={true}
                onBlur={onBlur}
                required={required || false}
                disabled={disabled || false}
                aria-required={required || false}
                aria-label={label}
                aria-describedby={`${helper ? `${name}-helper` : ""} ${
                  form.errors[name] ? `${name}-error` : ""
                }`}
                onChange={event => handleChange(event, form)}
                className={inputClassName}
              />
            )}

            {helper && (
              <p id={`${name}-helper`} className="mt-2 text-sm text-gray-600">
                {helper}
              </p>
            )}
            {error && (
              <div
                id={`${name}-field-error`}
                className="mt-2 text-sm text-coachPink"
              >
                {error}
              </div>
            )}
          </>
        )}
      </Field>
    </div>
  );
};
