import { Link } from "react-router-dom";

interface ContentSectionProperties {
  h1?: string;
  h2?: string;
  bgClasses?: string;
  anchorId?: string;
  content: JSX.Element;
  ctaButtonText?: string;
  ctaButtonPath?: string;
}

export const ContentSection = (
  properties: ContentSectionProperties,
): JSX.Element => {
  return (
    <section
      id={properties.anchorId}
      className={`overflow-hidden ${properties.bgClasses ?? ""}`}
    >
      <div className="max-w-7xl mx-auto w-full">
        <div className="py-12 xs:px-4 sm:px-8">
          {properties.h1 && (
            <h1 className="text-syllabyteTurquoise font-semibold tracking-wide uppercase">
              {properties.h1}
            </h1>
          )}
          {properties.h2 && (
            <h2 className="mt-2 text-3xl font-extrabold sm:text-4xl">
              {properties.h2}
            </h2>
          )}
          <div className="mt-6 prose max-w-full">{properties.content}</div>
          {properties.ctaButtonText && properties.ctaButtonPath && (
            <div className="mt-12">
              <Link
                to={properties.ctaButtonPath}
                className="mx-auto px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-syllabyteTurquoise hover:bg-opacity-80"
              >
                {properties.ctaButtonText}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
