import workingImage from "../images/ai-face/ai-face-working.svg";
import onImage from "../images/ai-face/ai-face-on.svg";
import happyImage from "../images/ai-face/ai-face-happy.svg";
import offlineImage from "../images/ai-face/ai-face-offline.svg";

export enum AiFaceType {
  Working = "working",
  On = "on",
  Happy = "happy",
  Offline = "offline",
}

export interface AiFaceProperties {
  type: AiFaceType;
}

export const AiFace = (properties: AiFaceProperties): JSX.Element => {
  const { type } = properties;

  let imgSource;

  switch (type) {
    case AiFaceType.Working: {
      imgSource = workingImage;
      break;
    }
    case AiFaceType.On: {
      imgSource = onImage;
      break;
    }
    case AiFaceType.Happy: {
      imgSource = happyImage;
      break;
    }
    case AiFaceType.Offline: {
      imgSource = offlineImage;
      break;
    }
    default: {
      imgSource = "";
      break;
    }
  }

  return (
    <div className="overflow-hidden text-center">
      <img loading="lazy" className="-mt-2 -mb-1 h-16" src={imgSource} alt="" />
    </div>
  );
};
