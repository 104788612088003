import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { MSALInstance } from "../App";
import { loginRequest } from "./msal";

export const teachersyllabyteAxios = axios.create({
  baseURL: process.env.REACT_APP_SYLLABYTE_API_BASE,
  paramsSerializer: {
    indexes: true,
  },
});

teachersyllabyteAxios.interceptors.request.use(
  async config => {
    const accounts = MSALInstance.getAllAccounts();
    if (!accounts || accounts.length === 0) {
      // no accounts in play so just return the config;
      return config;
    }

    await MSALInstance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
      .then(tokenResponse => {
        config.headers.Authorization = "Bearer " + tokenResponse.accessToken;
        return config;
      })
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          console.log(
            "an axios request failed with an InteractionRequiredAuthError, logging out to refresh account",
          );
          MSALInstance.logoutRedirect({ account: accounts[0] });
        }
      });

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

teachersyllabyteAxios.interceptors.response.use(
  function (response) {
    // just return the response
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        // if the current page isn't the login page then save it

        if (window.location.pathname !== "/login") {
          localStorage.setItem("lastUrl", window.location.href);
        }

        // clear state and head to /login
        console.log("Unauthenticated. Logging Out", error);
        MSALInstance.logoutRedirect();
      }

      if (error.response.status === 403) {
        // Redirect to /pricing
        window.location.href = "/pricing";
      }

      if (error.response.status === 429) {
        // Dispatch a `tooManyRequests` event
        const event = new CustomEvent("tooManyRequests", {
          detail: { message: "Too Many Requests" },
        });
        window.dispatchEvent(event);
      }
    }
    return Promise.reject(error);
  },
);

export interface TooManyRequestsResponseEvent {
  message?: string;
}
