import { SharedItem } from "../../../types/SharedItem";
import {
  generateBreadcrumb,
  Breadcrumbs,
} from "../../../components/Breadcrumbs";
import { MetaTags } from "../../../components/MetaTags";
import { ProfileBadge } from "../../../components/profile/ProfileBadge";
import { UploadCard } from "../../../components/uploads/UploadCard";
import { useEffect } from "react";

export interface SharedUploadViewerProperties {
  accessKey?: string;
  goalId?: string;
  sharedItem: SharedItem;
}

export function SharedUploadViewer(
  properties: SharedUploadViewerProperties,
): JSX.Element {
  const { ownerId, ownerEmail, ownerName, id, sharedUpload } =
    properties.sharedItem;

  useEffect(() => {
    console.log("upload properties::", properties);
  }, []);

  const upload = sharedUpload?.upload;
  if (upload == undefined) {
    throw new Error("upload must be provided");
  }

  const titleTag = `Shared Upload: ${upload.title}`;
  const descriptionTag = `Shared Upload from ${ownerName}`;

  const breadcrumbs = generateBreadcrumb(
    generateBreadcrumb([], "Sharing & Privacy", "/sharing"),
    `Upload: "${upload?.title}" from ${ownerName}` || "",
    `/shared/upload/${ownerId}/${id}`,
    `${ownerId}/${id}`,
  );

  return (
    <main>
      <MetaTags title={titleTag} description={descriptionTag} />

      {breadcrumbs.length > 0 && (
        <Breadcrumbs
          basePath="/dashboard"
          breadcrumbs={breadcrumbs}
        ></Breadcrumbs>
      )}

      <div className="max-w-6xl w-full mx-auto xs:pt-8 sm:pt-12 xs:pb-4 sm:pb-8 xs:px-4 sm:px-8">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <ProfileBadge id={ownerId} name={ownerName} email={ownerEmail} />
          </div>
          <div className="ml-4 mt-4 flex-shrink-0 flex"></div>
        </div>

        <hr className="mt-6 mb-6" />

        <UploadCard upload={upload}></UploadCard>
        <p>{upload.description}</p>
      </div>
    </main>
  );
}
