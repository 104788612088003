import { Link, NavLink } from "react-router-dom";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import logoDark from "../../images/branding/syllabyte-horizontal-logo.svg";
import clsx from "clsx";
import { NavItem } from "../../types/NavItem";
import { Badge } from "../Badge";

interface NavBarProperties {
  navItems: NavItem[];
}

export const NavBar = (properties: NavBarProperties): JSX.Element => {
  const { navItems } = properties;

  const defaultLinkStyle =
    "bg-opacity-0 text-center text-md border-syllabyteGrey hover:border-syllabyteTurquoise border-b-2 font-medium px-1 py-1 mb-1";
  const mobileLinkStyle =
    "bg-opacity-0 text-md block font-medium rounded-md bg-white px-3 py-2 hover:bg-opacity-10";

  return (
    <Disclosure as="nav" className="">
      {({ open, close }) => (
        <>
          <div className="max-w-7xl mx-auto xs:px-4 sm:px-8">
            <div className="flex items-center justify-between h-20">
              <div className="flex items-center">
                <div className="flex-shrink-0 mr-6">
                  <Link data-id="nav-logo-home" to="/">
                    <img
                      className="block md:hidden xs:h-12 sm:h-14 w-auto"
                      src={logoDark}
                      alt="syllabyte.ai"
                    />
                    <img
                      className="hidden md:block xs:h-12 sm:h-14 w-auto"
                      src={logoDark}
                      alt="syllabyte.ai"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex">
                <div className="hidden md:block">
                  <div className="flex space-x-4 mr-6">
                    {navItems.map(item => {
                      if (item.path)
                        return (
                          <NavLink
                            to={item.path}
                            key={item.label}
                            onClick={() => close()}
                            className={({ isActive }) => {
                              return clsx(
                                defaultLinkStyle,
                                isActive ? "border-syllabyteTurquoise" : "",
                              );
                            }}
                          >
                            {item.label}
                            {item.new && (
                              <Badge
                                text="New"
                                className="ml-1 mb-1"
                                backgroundColour="bg-green-500"
                                foregroundColour="text-white"
                              ></Badge>
                            )}
                          </NavLink>
                        );

                      if (item.flyout)
                        return (
                          <Popover key={item.label} className="relative">
                            {({ close: closePopover }) => (
                              <>
                                <Popover.Button
                                  className={clsx(
                                    defaultLinkStyle,
                                    "group inline-flex items-center",
                                  )}
                                >
                                  <span>{item.label}</span>
                                  <ChevronDownIcon
                                    className={clsx(
                                      "text-syllabyteBlack ml-2 h-5 w-5",
                                    )}
                                    aria-hidden="true"
                                  />
                                </Popover.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute z-20 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                        {item?.flyout?.navItems.map(
                                          flyoutItem => (
                                            <NavLink
                                              end
                                              to={flyoutItem.path || ""}
                                              onClick={() => {
                                                close();
                                                closePopover();
                                              }}
                                              key={flyoutItem.label}
                                              className={({ isActive }) => {
                                                return clsx(
                                                  "-m-3 p-5 cursor-pointer flex items-start rounded-lg hover:bg-gray-100 transition ease-in-out duration-150",
                                                  isActive
                                                    ? "border-white"
                                                    : "",
                                                );
                                              }}
                                            >
                                              <img
                                                className="w-16 -ml-4 -mr-1 -mt-1 float-left"
                                                src={flyoutItem.imageSrc}
                                                alt={flyoutItem.label}
                                              />
                                              <div className="ml-4">
                                                <p className="font-medium">
                                                  {flyoutItem.label}
                                                </p>
                                                <p className="mt-1 text-sm text-gray-600">
                                                  {flyoutItem.description}
                                                </p>
                                              </div>
                                            </NavLink>
                                          ),
                                        )}
                                      </div>
                                      <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0">
                                        <div className="flow-root w-full">
                                          <NavLink
                                            end
                                            onClick={() => {
                                              close();
                                              closePopover();
                                            }}
                                            to={item.flyout?.footerPath || ""}
                                            className="-m-3 p-3 flex items-center rounded-md font-medium hover:bg-gray-100 transition ease-in-out duration-150"
                                          >
                                            <ArrowRightIcon
                                              className="flex-shrink-0 h-6 w-6 text-gray-600"
                                              aria-hidden="true"
                                            />
                                            <span className="ml-3">
                                              {item.flyout?.footerText}
                                            </span>
                                          </NavLink>
                                        </div>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        );
                      return <div>Invalid Nav Item</div>;
                    })}
                  </div>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-syllabyteTurquoise hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-syllabyteTurquoise">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navItems.map(item => {
                if (item.path)
                  return (
                    <NavLink
                      end
                      to={item.path}
                      key={item.label}
                      onClick={() => close()}
                      className={({ isActive }) => {
                        return clsx(
                          mobileLinkStyle,
                          isActive ? "underline" : "",
                        );
                      }}
                    >
                      {item.label}
                    </NavLink>
                  );

                if (item.flyout)
                  return (
                    <div key={item.label}>
                      <p className={mobileLinkStyle}>{item.label}</p>
                      {item?.flyout?.navItems.map(flyoutItem => (
                        <NavLink
                          end
                          onClick={() => close()}
                          to={flyoutItem.path || ""}
                          key={flyoutItem.path}
                          className={({ isActive }) => {
                            return clsx(
                              mobileLinkStyle,
                              "pl-8",
                              isActive ? "underline" : "",
                            );
                          }}
                        >
                          {flyoutItem.label}
                        </NavLink>
                      ))}
                    </div>
                  );

                return <div>Invalid Nav Item</div>;
              })}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
