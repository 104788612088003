interface ProgressBarProperties {
  progress: number;
  max: number;
  paused: boolean;
}

export const ProgressBar = (properties: ProgressBarProperties): JSX.Element => {
  const { progress, max, paused } = properties;
  const width = (progress / max) * 100;

  return (
    <div className="w-full h-2 bg-gray-200 rounded-full">
      <div
        style={{ width: `${width}%` }}
        className={`h-full rounded-full ${
          paused ? "bg-yellow-500" : "bg-syllabyteTurquoise"
        }`}
      ></div>
    </div>
  );
};
