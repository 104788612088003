import { Link } from "react-router-dom";
import { UploadIndex } from "../../types/Upload";
import { Badge } from "../Badge";
import { ProgressBar } from "../fields/ProgressBar";

interface UploadCardProperties {
  upload: UploadIndex;
  linkTitle?: boolean; // should the title be a link to the editor?
}

export function UploadCard(properties: UploadCardProperties): JSX.Element {
  const { upload, linkTitle } = properties;

  if (upload.size === undefined) {
    upload.size = 0;
  }

  const sizeInMB = (upload.size / (1024 * 1024)).toFixed(2);

  return (
    <div
      id={upload.id}
      className="mb-1 xs:-mt-1 mt-4 w-full flex break-words justify-between items-center flex-wrap sm:flex-nowrap"
    >
      <div className="mr-4 w-full">
        <div className="flex gap-x-2 mb-1">
          {upload.isbn && <Badge text={upload.isbn} />}
        </div>

        <div>
          {(linkTitle && (
            <Link
              to={`/uploads/editor/${upload.id}`}
              className="text-xl font-bold hover:underline"
            >
              {upload.title || "No Title"}
            </Link>
          )) || (
            <p
              className="text-xl font-bold cursor-not-allowed"
              title="Not ready yet!"
            >
              {upload.title || "No Title"}
            </p>
          )}

          <p className="text-sm text-gray-500 pb-2">
            {sizeInMB}mb {upload.fileExtension}{" "}
          </p>

          {upload.reference && (
            <p className="text-sm text-gray-500 py-1">{upload.reference}</p>
          )}

          <p>
            {upload.description && upload.description.length > 220
              ? `${upload.description.slice(0, 220)}...`
              : upload.description || ""}
          </p>
        </div>

        {!upload.processed && (
          <div>
            <p className="mt-2 mb-4 text-sm">{upload.message}</p>

            {!upload.isBroken && (
              <div className="mt-2 mb-4">
                <ProgressBar
                  progress={upload.sliceIndex || 0}
                  max={upload.slices || 100}
                  paused={false}
                ></ProgressBar>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
