import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppRoutes } from "./util/AppRoutes";
import ReactGA from "react-ga4";
import ScrollToTop from "./components/navigation/ScrollToTop";
import { ProfileProvider } from "./util/ProfileContext";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./util/msal";
import { MsalProvider } from "@azure/msal-react";

export const TeacherToolkitAppBaseUrl =
  process.env.REACT_APP_SYLLABYTE_APP_BASE || "";

export const MSALInstance = new PublicClientApplication(msalConfig);

function App(): JSX.Element {
  const queryClient = new QueryClient();

  const googleAnalyticsKey =
    process.env.REACT_APP_SYLLABYTE_GOOGLETAGMANAGER_ID;
  if (!googleAnalyticsKey) {
    throw new Error("Unable to determine google analytics configuration");
  }

  ReactGA.initialize(googleAnalyticsKey);

  return (
    <MsalProvider instance={MSALInstance}>
      <ProfileProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <ScrollToTop />
            <AppRoutes />
          </QueryClientProvider>
        </BrowserRouter>
      </ProfileProvider>
    </MsalProvider>
  );
}

export default App;
