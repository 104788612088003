import { Link } from "react-router-dom";
import { Lesson } from "../../types/Lesson";
import { MethodologyBadge } from "../MethodologyBadge";
import { EnhancedBadge } from "../EnhancedBadge";

interface LessonCardProperties {
  lesson: Lesson;
  linkTitle?: boolean; // should the title be a link to the editor?
}

export function LessonCard(properties: LessonCardProperties): JSX.Element {
  const { lesson, linkTitle } = properties;

  return (
    <div className="mb-1 xs:-mt-1 mt-4 flex break-words justify-between items-center flex-wrap sm:flex-nowrap">
      <div className="mr-4 mb-4">
        {lesson.enhancementEnabled && <EnhancedBadge />}

        {lesson.methodology && (
          <MethodologyBadge methodology={lesson.methodology} />
        )}

        {linkTitle && (
          <Link
            to={`/lessons/editor/${lesson.id}`}
            className="block text-xl font-bold hover:underline"
          >
            {lesson.title}
          </Link>
        )}

        {!linkTitle && (
          <span className="block text-xl font-bold mb-4 cursor-default">
            {lesson.title}
          </span>
        )}

        <p>
          {lesson.description && lesson.description.length > 220
            ? `${lesson.description.slice(0, 220)}...`
            : lesson.description || ""}
        </p>
      </div>
    </div>
  );
}
