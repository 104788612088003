import { Badge } from "./Badge";

export const EnhancedBadge = (): JSX.Element => {
  return (
    <Badge
      text="Enhanced"
      className="mr-2 mb-1"
      backgroundColour="bg-syllabyteBlack"
      foregroundColour="text-white"
    ></Badge>
  );
};
