import clsx from "clsx";

export enum LoaderTypes {
  ThinSkeleton = "thinskeleton",
  Skeleton = "skeleton",
  Spinner = "spinner",
}

interface LoadingProperties {
  loaderType?: LoaderTypes;
  colour?: string;
}

export function Loading(properties: LoadingProperties): JSX.Element {
  const { loaderType, colour } = properties;

  // const bgColourClass = colour ? `bg-${colour}` : "bg-gray-200";
  const textColourClass = colour ? `text-${colour}` : "text-white";

  if (!loaderType || loaderType === LoaderTypes.Skeleton) {
    return (
      <div className="flex space-x-4 animate-pulse">
        <div className="flex-1 space-y-4 py-4">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  if (loaderType === LoaderTypes.ThinSkeleton) {
    return (
      <div className="flex space-x-4 animate-pulse">
        <div className="flex-1 space-y-4 py-1">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
        </div>
      </div>
    );
  }

  if (loaderType === LoaderTypes.Spinner) {
    return (
      <svg
        className={clsx("animate-spin -ml-1 mr-3 h-5 w-5 ", textColourClass)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
  }

  return <></>;
}
