import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

export function Footer(): JSX.Element {
  const footerNavItems = [
    { path: "/?fl=1", label: "Home", end: true },
    { path: "/faq", label: "FAQ" },
    { path: "/pricing", label: "Pricing" },
  ];

  const socialNavItems = [
    {
      name: "Facebook",
      href: "https://www.facebook.com/syllabyteai",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      icon: (properties: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...properties}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "https://twitter.com/syllabyteai",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      icon: (properties: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...properties}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/syllabyteai/",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      icon: (properties: any) => (
        <svg
          fill="currentColor"
          data-icon="linkedin-in"
          viewBox="0 0 448 512"
          {...properties}
        >
          <path
            fill="currentColor"
            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
          ></path>
        </svg>
      ),
    },
  ];

  return (
    <footer className="bg-syllabyteBlack pt-12">
      <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {footerNavItems.map(item => (
            <div key={item.label} className="px-5 py-2">
              {item.path && (
                <NavLink
                  to={item.path}
                  className={({ isActive }) => {
                    return clsx(
                      "text-base text-white hover:text-gray-300 hover:border-b hover:border-gray-400",
                      isActive ? "border-b border-gray-400" : "",
                    );
                  }}
                >
                  {item.label}
                </NavLink>
              )}
            </div>
          ))}
        </nav>
        <div className="mt-12 xs:pt-0 md:pt-12 md:flex xs:text-center md:text-left xs:items-center md:justify-between">
          <p className="mt-8 text-gray-400 text-base xs:mb-4 md:mb-0 md:mt-0 md:order-1">
            ©2024 syllabyte.AI. By{" "}
            <a
              className="border-b border-gray-400 hover:border-gray-300"
              target="_blank"
              rel="noreferrer noopener nofollow"
              href="https://find-and-update.company-information.service.gov.uk/company/14040185"
            >
              <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
              CPD COACH LTD
            </a>{" "}
            All rights reserved.
          </p>
          <div className="flex xs:pt-4 md:pt-0 space-x-6 md:order-2">
            <a
              className="text-gray-400 hover:text-gray-300 border-b border-gray-400 hover:border-gray-300"
              target="_blank"
              rel="noreferrer noopener nofollow"
              title="Information Commissioner's Office (ICO) Registration"
              href="https://ico.org.uk/ESDWebPages/Entry/ZB504298"
            >
              <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
              ICO
            </a>{" "}
            <a
              className="text-gray-400 hover:text-gray-300 border-b border-gray-400 hover:border-gray-300"
              target="_blank"
              rel="noreferrer noopener nofollow"
              title="Status Page"
              href="https://syllabyteai.statuspage.io/"
            >
              <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
              Status
            </a>{" "}
            {socialNavItems.map(item => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noreferrer noopener nofollow"
                className="text-gray-400 hover:text-gray-300 hover:border-b hover:border-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
