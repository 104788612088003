import { useContext, useEffect } from "react";
import { Dropdown } from "./Dropdown";
import { ListItem } from "../../types/ListItem";
import { MARKETLIST } from "../../util/markets";
import { ProfileContext } from "../../util/ProfileContext";

interface MarketPickerProperties {
  label: string;
  onChange: (value: ListItem) => void;
  selectedValue?: ListItem;
  autoDetect?: boolean;
}

export const MarketPicker = (
  properties: MarketPickerProperties,
): JSX.Element => {
  const { label, onChange, selectedValue, autoDetect = false } = properties; // Default to false
  const { profile } = useContext(ProfileContext); // load default

  useEffect(() => {
    // set default market
    const loadDefaultDefaultMarket = async () => {
      // what's in the profile?
      if (profile?.market) {
        const market = MARKETLIST.find(
          existingMarket => existingMarket.key === profile.market,
        );
        if (market) {
          onChange(market);
        }
        return;
      } // Auto-detect based on location if autoDetect is true

      if (autoDetect) {
        try {
          const response = await fetch("https://ipapi.co/json", {
            mode: "cors",
          }); // load and read ipapi data
          const data = await response.json();
          const defaultMarket = data.languages.split(",")[0];
          if (defaultMarket) {
            let market = MARKETLIST.find(
              existingMarket => existingMarket.key === defaultMarket,
            ); // if they haven't found the market, just check the first two characters.
            if (!market) {
              market = MARKETLIST.find(
                existingMarket =>
                  existingMarket.key.slice(0, 2) === defaultMarket.slice(0, 2),
              );
            } // set selected
            if (market) {
              onChange(market);
            }
          }
        } catch (error) {
          console.error("Failed to fetch location data", error);
        }
      }
    };

    if (!selectedValue || !selectedValue.key || selectedValue.key === "") {
      loadDefaultDefaultMarket();
    }
  }, [profile, autoDetect, selectedValue?.key, onChange]);

  return (
    <Dropdown
      label={label}
      name="market"
      options={MARKETLIST}
      onChange={(value: string) => {
        const market = MARKETLIST.find(option => option.key === value);
        if (market) {
          onChange(market);
        }
      }}
      selectedKey={selectedValue?.key || ""}
    ></Dropdown>
  );
};
