export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_SYLLABYTE_AZUREB2C_CLIENTID as string,
    authority: process.env.REACT_APP_SYLLABYTE_AZUREB2C_AUTHORITY as string,
    redirectUri: process.env.REACT_APP_SYLLABYTE_AZUREB2C_REDIRECTURI as string,
    postLogoutRedirectUri: process.env
      .REACT_APP_SYLLABYTE_AZUREB2C_LOGOUTURI as string,
    knownAuthorities: [
      process.env.REACT_APP_SYLLABYTE_AZUREB2C_KNOWNAUTHORITY as string,
    ],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: [
    "openid",
    "profile",
    "email",
    "offline_access",
    process.env.REACT_APP_SYLLABYTE_AZUREB2C_APIACCESSCLAIM as string,
  ],
};
